<template lang="html">
  <vs-sidebar static-position type="sidebar-menu" default-index="1" color="primary" class="sidebarx" spacer v-model="active">
    <vs-sidebar-item index="1" href="javascript:void(0)">
      <div class="sidebar-item">
        <div class="sidebar-item-title">Basics</div>
        <ul class="sidebar-subitems-container">
          <li class="sidebar-subitem" :class="activeSubitem === 1 ? 'sidebar-subitem-active': ''" index="1" @click="switchActiveSubItem(1)">
            <a @click="show('colors')">Colors</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 2 ? 'sidebar-subitem-active': ''" index="2" @click="switchActiveSubItem(2)">
            <a @click="show('typography')">Typography</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 3 ? 'sidebar-subitem-active': ''" index="3" @click="switchActiveSubItem(3)">
            <a @click="show('buttons')">Buttons</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 4 ? 'sidebar-subitem-active': ''" index="4" @click="switchActiveSubItem(4)">
            <a @click="show('icons')">Icons</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 5 ? 'sidebar-subitem-active': ''" index="5" @click="switchActiveSubItem(5)">
            <a @click="show('logos')">Logos</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 6 ? 'sidebar-subitem-active': ''" index="6" @click="switchActiveSubItem(6)">
            <a @click="show('badges')">Badges</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 7 ? 'sidebar-subitem-active': ''" index="7" @click="switchActiveSubItem(7)">
            <a @click="show('input-fields')">Input Fields</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 8 ? 'sidebar-subitem-active': ''" index="8" @click="switchActiveSubItem(8)">
            <a @click="show('grid')">Grid</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 9 ? 'sidebar-subitem-active': ''" index="9" @click="switchActiveSubItem(9)">
            <a @click="show('spacing')">Spacing</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 10 ? 'sidebar-subitem-active': ''" index="10" @click="switchActiveSubItem(10)">
            <a @click="show('backgrounds')">Backgrounds</a>
          </li>
        </ul>
      </div>
    </vs-sidebar-item>
    <vs-sidebar-item index="2" href="javascript:void(0)">
      <div class="sidebar-item">
        <div class="sidebar-item-title">Components</div>
        <ul class="sidebar-subitems-container">
          <li class="sidebar-subitem" :class="activeSubitem === 1 ? 'sidebar-subitem-active': ''" index="1" @click="switchActiveSubItem(1)">
            <a @click="show('navigation')">Navigation</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 2 ? 'sidebar-subitem-active': ''" index="2" @click="switchActiveSubItem(2)">
            <a @click="show('footers')">Footers</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 3 ? 'sidebar-subitem-active': ''" index="3" @click="switchActiveSubItem(3)">
            <a @click="show('tables')">Tables</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 4 ? 'sidebar-subitem-active': ''" index="4" @click="switchActiveSubItem(4)">
            <a @click="show('headings')">Headings</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 5 ? 'sidebar-subitem-active': ''" index="5" @click="switchActiveSubItem(5)">
            <a @click="show('cards')">Cards</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 6 ? 'sidebar-subitem-active': ''" index="6" @click="switchActiveSubItem(6)">
            <a @click="show('popup')">Pop-ups</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 7 ? 'sidebar-subitem-active': ''" index="7" @click="switchActiveSubItem(7)">
            <a @click="show('messages')">Messages</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 8 ? 'sidebar-subitem-active': ''" index="8" @click="switchActiveSubItem(8)">
            <a @click="show('badges-comp')">Badges</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 9 ? 'sidebar-subitem-active': ''" index="9" @click="switchActiveSubItem(9)">
            <a @click="show('icons-comp')">Icons</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 10 ? 'sidebar-subitem-active': ''" index="10" @click="switchActiveSubItem(10)">
            <a @click="show('numbering')">Numbering</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 11 ? 'sidebar-subitem-active': ''" index="11" @click="switchActiveSubItem(11)">
            <a @click="show('product-fields')">Product fields</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 12 ? 'sidebar-subitem-active': ''" index="12" @click="switchActiveSubItem(12)">
            <a @click="show('summary-card')">Summary card</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 13 ? 'sidebar-subitem-active': ''" index="13" @click="switchActiveSubItem(13)">
            <a @click="show('payment')">Payment</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 14 ? 'sidebar-subitem-active': ''" index="14" @click="switchActiveSubItem(14)">
            <a @click="show('login')">Login</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 15 ? 'sidebar-subitem-active': ''" index="15" @click="switchActiveSubItem(15)">
            <a @click="show('extend-due-date')">Extend due date</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 16 ? 'sidebar-subitem-active': ''" index="16" @click="switchActiveSubItem(16)">
            <a @click="show('make-payment')">Make a payment</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 17 ? 'sidebar-subitem-active': ''" index="17" @click="switchActiveSubItem(17)">
            <a @click="show('change-payment')">Change payment method</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 18 ? 'sidebar-subitem-active': ''" index="18" @click="switchActiveSubItem(18)">
            <a @click="show('communication-card')">Communication card</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 19 ? 'sidebar-subitem-active': ''" index="19" @click="switchActiveSubItem(19)">
            <a @click="show('progress-indicator')">Progress indicator</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 20 ? 'sidebar-subitem-active': ''" index="20" @click="switchActiveSubItem(20)">
            <a @click="show('transaction-card')">Transaction card</a>
          </li>
          <li class="sidebar-subitem" :class="activeSubitem === 21 ? 'sidebar-subitem-active': ''" index="21" @click="switchActiveSubItem(21)">
            <a @click="show('agreement-card')">Agreement card</a>
          </li>
        </ul>
      </div>
    </vs-sidebar-item>
    <vs-sidebar-item index="3" href="javascript:void(0)">
      <div class="sidebar-item">
        <div class="sidebar-item-title">Layouts</div>
        <ul class="sidebar-subitems-container">
          <li class="sidebar-subitem" :class="activeSubitem === 1 ? 'sidebar-subitem-active': ''" index="1" @click="switchActiveSubItem(1)">
            <a @click="show('sidebar-layout')">Sidebar Layout</a>
          </li>
        </ul>
      </div>
    </vs-sidebar-item>
  </vs-sidebar>
</template>

<script>
  export default {
    data: () => ({
      activeSubitem: 1,
    }),
    components: {
    },
    methods: {
      show(showContent) {
        this.$emit('show', showContent);
      },
      switchActiveSubItem(subItemIndex) {
        this.activeSubitem = subItemIndex;
      },
    }
  };
</script>